import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'
import moment from 'moment'

import routes from 'app/routing/routes'
import { Tooltip } from 'components'

const COLUMNS = [
  {
    Header: "Nro",
    id: 'id',
    accessor: (row) => (
      <Tooltip text='Ver detalle'>
        <Link to={`${routes.INVENTORY_MOVEMENTS}/${row.id}`}>
          {row.id}
        </Link>
      </Tooltip>
    ),
    hasOrder: true,
  },
  {
    Header: <p className='m-0 p-0 text-center'>Creación</p>,
    id: 'created_at',
    accessor: (row) => (
      <div className='text-center'>
        <p className='m-0 p-0'>Fecha: {moment(row.created_at).format('DD/MM/YYYY')}</p>
        <p className='m-0 p-0'>Usuario: {row.created_by_user}</p>
      </div>
    ),
    hasOrder: true,
  },
  // {
  //   Header: "Tipo",
  //   accessor: 'depot_movement_type',
  // },
  {
    Header: "Origen",
    id: 'storage_space_id_origin',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.storage_space_origin.name}</p>
    )
  },
  {
    Header: "Destino",
    id: 'storage_space_id_dest',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.storage_space_dest.name}</p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Artículos</p>,
    id: 'detail_count',
    accessor: (row) => (
      <div className='text-center'>
        {row?.detail_count || 0}

        {row?.articles &&
          <OverlayTrigger
            placement='right'
            overlay={
              <TooltipBS id={`tooltip-${row.id}`}>
                <div>
                  {row.articles.split(',').map((item, index) => (
                    <div key={index} className='text-start'>{item.trim()}</div>
                  ))}
                </div>
              </TooltipBS>
            }
          >
            <i className='bi bi-info-circle text-warning ms-2' />
          </OverlayTrigger>
        }
      </div>
    )
  },
  {
    Header: "",
    id: 'has_refrigeration',
    accessor:(row) => (
      <>
        {Boolean(row.has_refrigeration) &&
          <span className='ps-4 pe-4'>
            <i className='bi bi-snow text-primary fs-3' />
          </span>
        }
      </>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Estado</p>,
    id: "depot_movement_status",
    accessor: (row) => (
      <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
        {row.depot_movement_status}
      </span>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Cancelación</p>,
    id: 'cancel_date',
    accessor: (row) => (
      <div className='text-center'>
        {row?.canceled_by_user_id
          ?
            <>
              <p className='m-0 p-0'>Fecha: {moment(row?.cancel_date || '').format('DD/MM/YYYY')}</p>
              <p className='m-0 p-0'>Usuario: {row.cancel_by_user}</p>
            </>
          : '-'
        }
      </div>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Confirmación</p>,
    id: 'confirm_date',
    accessor: (row) => (
      <div className='text-center'>
      {row?.confirm_user_id
        ?
          <>
            <p className='m-0 p-0'>Fecha: {moment(row?.confirm_date || '').format('DD/MM/YYYY')}</p>
            <p className='m-0 p-0'>Usuario: {row.confirm_user_name}</p>
          </>
        : '-'
      }
    </div>
    )
  },
  {
    Header: <p className='m-0 p-0'>Remito</p>,
    id: 'remito_id',
    accessor: (row) => (
      <>
        {row?.remito_id && <p className='m-0 p-0'>{row.remito}</p>}
      </>
    )
  }
]

export {
  COLUMNS
}