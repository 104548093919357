import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'

import { useSalesOrders, useSellers, useUsers } from 'hooks'
import { Input, SelectField } from 'components'

import { SalesOrderStatusI } from 'interfaces'

const optionStatus = [
  { value: 1, label: "Pendiente" },
  { value: 2, label: "Operaciones" },
  { value: 3, label: "Finalizado" },
]

export const labelColor = (sales_order_status_id) => {
  switch(sales_order_status_id){
    case SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA:
    case SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO:
    case SalesOrderStatusI.PENDIENTE_PREPARACION:
    case SalesOrderStatusI.PENDIENTE_FACTURACION:
    case SalesOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo";
    case SalesOrderStatusI.ENTREGADO:
      return "estado-verde";
    case SalesOrderStatusI.BORRADOR:
      return "estado-gris";
    case SalesOrderStatusI.FALTA_STOCK:
    case SalesOrderStatusI.EN_REVISION:
    case SalesOrderStatusI.NO_AUTORIZADO:
    case SalesOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja";
    case SalesOrderStatusI.EN_DISTRIBUCION:
    case SalesOrderStatusI.EN_PREPARACION:
    case SalesOrderStatusI.EN_DESPACHO:
      return "estado-azul";
    case SalesOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste";
    case SalesOrderStatusI.CANCELADO:
      return "estado-rojo";
    default:
      return ""
  }
}

const formikInitialValues = {
  id_text: '',
  purchase_order: '',
  out_order_id: '',
  from_date: '',
  to_date: '',
  nombre: '',
  documento: '',
  from_auth_date: '',
  to_auth_date: '',
  paciente: '',
  paciente_documento: '',
  seller_id_filter: [],
  article_text: '',
  user_id_filter: [],
  grouped_status_id: [],
  sales_order_status_id_filter: [],
}

const Filter = ({ handleData, query, handleQuery, handleTotalCount }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialValues, setInitialValues] = useState(formikInitialValues)

  const { loading, handleGetSalesOrders, handleGetSalesOrderStatus } = useSalesOrders()
  const [status, setStatus] = useState([])

  const { handleGetSellers } = useSellers()
  const [sellers, setSellers] = useState([])

  const { handleGetUsers } = useUsers()
  const [users, setUsers] = useState([])

  const filterData = (values) => {
    let params = {
      ...query,
      ...values,
      id_text: values?.id_text ? values.id_text : undefined,
      purchase_order: values?.purchase_order ? values.purchase_order : undefined,
      out_order_id: values?.out_order_id ? values.out_order_id : undefined,
      text: values?.text ? values.text : undefined,
      article_text: values?.article_text ? values.article_text : undefined,
      sales_order_status_id_filter: values?.sales_order_status_id_filter ? values.sales_order_status_id_filter : undefined,
      user_id_filter: values?.user_id_filter ? values.user_id_filter : undefined,
      grouped_status_id: values?.grouped_status_id ? values.grouped_status_id : undefined,
      getDetail: true,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = {
        ...filterData(values),
        pageNumber: 1,
        pageSize: 10,
      }
      const response = await handleGetSalesOrders(params, '')
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.sales_order_status_id)
      }))

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        sales_orders: { ...params },
      }))

      handleQuery({ ...params })
      handleData(data)
      handleTotalCount(response.data.metadata.count)
    } catch (error) {
      handleData([])
      handleTotalCount(0)
      toast.error(error.message)
    }
  }

  const getSalesOrderStatus = useCallback(async () => {
    let params = {}

    try {
      const response = await handleGetSalesOrderStatus(params, 'token')
      const data = response.data.result.map(d => ({ value: d.id, label: d.label }))

      setStatus(data)
    } catch (error) {
      setStatus([])
      toast.error(error.message)
    }
  }, [handleGetSalesOrderStatus])

  const getSellers = useCallback(async () => {
    try {
      const response = await handleGetSellers('', '');
      const data = response.data.result.map(d => ({
        value: d.id,
        label: `${d.first_name} ${d.last_name}`,
      }))

      setSellers(data)
    } catch (error) {
      setSellers([])
      toast.error(error.message)
    }
  }, [handleGetSellers])

  const getUsers = useCallback(async () => {
    try {
      const params = {
        //
      }
      const response = await handleGetUsers(params)
      const data = response.data.result.map(d => ({ value: d.id, label: d.name }))
      setUsers(data)
    } catch (error) {
      setUsers([])
      toast.error(error.message)
    }
  }, [handleGetUsers])

  useEffect(() => {
    getSalesOrderStatus()
    getSellers()
    getUsers()
  }, [getSalesOrderStatus, getSellers, getUsers])

  useEffect(() => {
    if (currentUser?.user_id) {
      const { sales_orders } = currentUser

      setInitialValues({
        ...formikInitialValues,
        id_text: sales_orders?.id_text || '',
        purchase_order: sales_orders?.purchase_order || '',
        out_order_id: sales_orders?.out_order_id || '',
        from_date: sales_orders?.from_date || '',
        to_date: sales_orders?.to_date || '',
        nombre: sales_orders?.nombre || '',
        documento: sales_orders?.documento || '',
        from_auth_date: sales_orders?.from_auth_date || '',
        to_auth_date: sales_orders?.to_auth_date || '',
        paciente: sales_orders?.paciente || '',
        paciente_documento: sales_orders?.paciente_documento || '',
        seller_id_filter: sales_orders?.seller_id_filter || [],
        article_text: sales_orders?.article_text || '',
        user_id_filter: sales_orders?.user_id_filter ? sales_orders.user_id_filter : [currentUser.user_id],
        grouped_status_id: sales_orders?.grouped_status_id || [],
        sales_order_status_id_filter: sales_orders?.sales_order_status_id_filter || [SalesOrderStatusI.BORRADOR],
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_text' name='id_text' />
              <p className='text-muted'>Nro.</p>
            </div>

            <div className='col-2'>
              <Input id='purchase_order' name='purchase_order' />
              <p className='text-muted'>Nro. Orden de compra</p>
            </div>

            <div className='col-4'>
              <Input id='out_order_id' name='out_order_id' placeholder='Ej: 1234, 4321' />
              <p className='text-muted'>ID OE</p>
            </div>

            <div className='col-2'>
              <Input id='from_date' name='from_date' type='date' />
              <p className='text-muted'>F. desde</p>
            </div>

            <div className='col-2'>
              <Input id='to_date' name='to_date' type='date' />
              <p className='text-muted'>F. hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Input id='nombre' name='nombre' />
              <p className='text-muted'>ID Cliente / Razón social</p>
            </div>

            <div className='col-2'>
              <Input id='documento' name='documento' />
              <p className='text-muted'>Documento</p>
            </div>

            <div className='col-2'>
              <Input id='from_auth_date' name='from_auth_date' type='date' />
              <p className='text-muted'>Desde F. autorización</p>
            </div>

            <div className='col-2'>
              <Input id='to_auth_date' name='to_auth_date' type='date' />
              <p className='text-muted'>Hasta F. autorización</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input id='paciente' name='paciente' />
              <p className='text-muted'>ID Paciente / Nombre</p>
            </div>

            <div className='col-4'>
              <Input id='paciente_documento' name='paciente_documento' />
              <p className='text-muted'>Documento / Nro.afiliado de paciente</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='seller_id_filter'
                name='seller_id_filter'
                options={sellers}
                marginTop={'-20px'}
                placeholder='Seleccionar'
                isMulti
              />
              <p className='text-muted'>Vendedor</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-8'>
              <Input id='article_text' name='article_text' />
              <p className='text-muted'>Artículo / Droga</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='user_id_filter'
                name='user_id_filter'
                options={users}
                placeholder='Seleccione'
                marginTop={'-20px'}
                isMulti
              />
              <p className='text-muted'>Usuario creación</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <SelectField
                id='grouped_status_id'
                name='grouped_status_id'
                options = {optionStatus}
                isMulti
                placeholder='Seleccione un estado'
                marginTop={'-20px'}
              />
              <p className='text-muted'>Grupo de estados</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='sales_order_status_id_filter'
                name='sales_order_status_id_filter'
                options = {status}
                placeholder='Seleccione un estado'
                marginTop={'-20px'}
                isMulti
              />
              <p className='text-muted'>Estados</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant='secondary'
                onClick={() => {
                  resetForm({ values: formikInitialValues })
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className='bi bi-zoom-out mb-1' />
                Limpiar
              </Button>

              <Button type='submit' variant='primary' disabled={loading}>
                <i className='bi bi-search mb-1' />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter