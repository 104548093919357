import { useState, useCallback } from 'react';

import { TransactionalDocuments } from 'services';

const useTransactionalDocuments = () => {
  const [loading, setLoading] = useState(false);

  const handleGetDocuments = useCallback(async (params) => {
    try {
      let query = "";
      const soloDefinidos = Object.keys(params).reduce((acc, key) => {
        if (params[key] !== undefined) {
          acc[key] = params[key];
        }
        return acc;
      }, {});

      query = new URLSearchParams(soloDefinidos);

      setLoading(true)
      const response = await TransactionalDocuments.getDocuments(query)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDocument = useCallback(async (transactional_document_type_id, id) => {
    try {
      setLoading(true)

      const response = await TransactionalDocuments.getDocument(transactional_document_type_id, id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handlePrintFactura = useCallback(async (transactional_document_type_id, id) => {
    try {
      setLoading(true)
      const response = await TransactionalDocuments.printFactura(transactional_document_type_id, id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handlePrintRemito = useCallback(async (id, copias) => {
    try {
      setLoading(true)
      const response = await TransactionalDocuments.printRemito(id, copias)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateCreditNote = useCallback(async (transactional_document_type_id, id, params) => {
    try {
      setLoading(true)
      const response = await TransactionalDocuments.createCreditNote(transactional_document_type_id, id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetPuntosVenta = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await TransactionalDocuments.getPuntosVenta(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleEmitirDiferido = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await TransactionalDocuments.emitirDiferido(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDescartarDiferido = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await TransactionalDocuments.descartarDiferido(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUnificarComprobantes = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await TransactionalDocuments.unificarComprobantes(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetDocuments,
    handleGetDocument,
    handlePrintFactura,
    handlePrintRemito,
    handleCreateCreditNote,
    handleGetPuntosVenta,
    handleEmitirDiferido,
    handleDescartarDiferido,
    handleUnificarComprobantes
  };
};

export default useTransactionalDocuments;