import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomTable } from 'components'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useClickOutside, useOutOrders } from 'hooks'
import useTransactionalDocuments from 'hooks/useTransactionalDocuments'

import { TableContainer } from 'containers'
import ContextMenu from 'components/ContextMenu'
import Filter from './Filter'
import useGetColumns from './columns'
import routes from 'app/routing/routes'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { helix } from 'ldrs'
import { OutOrderStatusI } from 'interfaces'


const initialObQuery = {
  pageNumber: "1",
  pageSize: "10",
  tipo_comprobante_codigo_interno: '1',
  sortField:'id',
  sortOrder:'desc'
}

export const labelColor = (out_order_status_id) => {
  switch (out_order_status_id) {
    case OutOrderStatusI.PENDIENTE_PREPARACION:
    case OutOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste";
    case OutOrderStatusI.EN_PREPARACION:
    case OutOrderStatusI.EN_DESPACHO:
    case OutOrderStatusI.EN_DISTRIBUCION:
      return "estado-azul";
    // case OutOrderStatusI.EN_PREPARACION:
    //   return "estado-gris";
    case OutOrderStatusI.CANCELADO:
      return "estado-rojo";
    case OutOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo";
    case OutOrderStatusI.ENTREGADA:
      return "estado-verde";
    case OutOrderStatusI.EN_REVISION:
    case OutOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja";
    default:
      return ""
  }
}

const GroupedInvoices = () => {
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const [filter, setFilter] = useState(false)
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { handleGetDocuments, loading } = useTransactionalDocuments()

  const getDocuments = async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }
      const response = await handleGetDocuments(params)

      if (response.data.result) {
        const data = response.data.result

        const dataColored = data.map(d => ({ 
            ...d, 
            label_color: labelColor(d.out_order_status_id)
          }))
        setData(dataColored);
        setTotalCount(response.data.metadata.count);
      }
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }


  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()
  const navigate = useNavigate();

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }


  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []



    return actions
  }

  const nuevoAgrupamiento = ()=>{

  }


  const columns = useMemo(() => [
    ...COLUMNS,
    // {
    //   Header:  () => (<div style={{ textAlign: "center" }}>Estado</div>),
    //   id:'estado',
    //   className:'col-status',
    //   accessor: (row) => {
    //       return (
    //         <span className={ `badge ${row.label_color} w-100 justify-content-center `}>
    //           {row.out_order_status_name}
    //         </span>
    //       )
    //     }
      
    // },
    // {
    //   Header: '',
    //   id: 'comprobante_cancelacion_id',
    //   accessor: (row) => (
    //     row.comprobante_cancelacion_id 
    //       ? <OverlayTrigger overlay={<Tooltip>Tiene asociada la nota de crédito #{row.comprobante_cancelacion_id} </Tooltip>}>
    //           <i className="bi bi-code-square text-danger"  />
    //         </OverlayTrigger>
    //       : <></>
    //   )
    // },
    // {
    //   Header: '',
    //   id: 'actions',
    //   className:'col-icons',
    //   accessor: (row) => (
    //     (row?.estado?.toUpperCase() !== 'ERROR_NEGOCIO_AFIP')?
    //       <i id='threeDots' 
    //         className="bi bi-three-dots-vertical" 
    //         style={{ fontSize: "1.5rem", cursor: "pointer" }} 
    //         onClick={event => handleContextMenu(event, row)} 
    //         ref={threeDotsRef} 
    //       />
    //       :
    //       <></>

    //   )
    // }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery:queryMD,
    setObQuery:setQueryMD
  };

  useEffect(() => {
    if(filter)
      getDocuments()
  }, [filter, queryMD])

  useEffect(()=>{
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title='Facturas agrupadas'
        goTo={routes.ADMINISTRATIVE_GROUPED_INVOICES_NEW}
        goToText='Nuevo agrupamiento'
        filter={<Filter setData={setData} setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} />}
      >
        {
          loading &&
          <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>
        }
        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} 
              handleContextMenu={handleContextMenu}  queryMD={queryMD} setQueryMD={setQueryMD} />  

      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default GroupedInvoices