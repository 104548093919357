import service from '../../service'

const PATH = {
  depotMovement: '/stock/depot-movement',
  depotMovementStatus: '/stock/depot-movement/status',
}

export class Stock {
  // -- Depot movements
  static getDepotMovements = async (params) => {
    const response = await service.get(PATH.depotMovement, {
      params
    })

    return response
  }

  static getDepotMovement = async (id) => {
    const response = await service.get(`${PATH.depotMovement}/${id}`)
    return response
  }

  static getDepotMovementStatus = async () => {
    const response = await service.get(PATH.depotMovementStatus)
    return response
  }

  static getDepotMovementDetail = async (id, params) => {
    const response = await service.get(`${PATH.depotMovement}/${id}/detail`, {
      params,
    })
    return response
  }

  static getDepotMovementDetailGrouped = async (id, params) => {
    const response = await service.get(`${PATH.depotMovement}/${id}/detail-grouped`, {
      params,
    })
    return response
  }

  static createDepotMovement = async (data) => {
    const response = await service.post(PATH.depotMovement, data)
    return response
  }

  static cancelDepotMovement = async (id) => {
    const response = await service.post(`${PATH.depotMovement}/${id}/cancelar`)
    return response
  }

  static createDepotMovementDetail = async (data) => {
    const {movement_id, ...rest} = data;
    const response = await service.post(`${PATH.depotMovement}/${movement_id}/detail`, rest)
    return response
  }

  static createDepotMovementDetailWithReader = async (data) => {
    const {movement_id, code, ...rest} = data;
    const response = await service.post(`${PATH.depotMovement}/${movement_id}/detail/scan-code/${code}`, rest)
    return response
  }

  static createDepotMovementConfirm = async (movement_id) => {
    const response = await service.post(`${PATH.depotMovement}/${movement_id}/confirm`, {})
    return response
  }

  static deleteDepotMovementDetail = async (id, product_var_id) => {
    const response = await service.delete(`${PATH.depotMovement}/${id}/detail/var/${product_var_id}`)
    return response
  }

  static deleteDepotMovementDetailById = async (id, detail_id) => {
    const response = await service.delete(`${PATH.depotMovement}/${id}/detail/${detail_id}`)
    return response
  }

  static generarRemitoInterno = async (id) => {
    const response = await service.post(`${PATH.depotMovement}/${id}/generar-remito-interno`)
    return response
  }

  static printRemito = async (id, copias) => {
    const response = await service.get(`${PATH.depotMovement}/${id}/print/remito`, {
      params: {
        copias: copias,
      },
      responseType: 'blob',
    })
    return response
  }

  static printInternalWaybill = async (id, copias) => {
    const response = await service.get(`${PATH.depotMovement}/${id}/print/remito?copias=${copias}`,{
      responseType: 'blob',
    });
    return response;
  };

}