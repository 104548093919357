import { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Input, SelectField, SwitchField } from 'components'
import moment from 'moment'
import { BorderColor } from '@mui/icons-material'

const initialValues = {
    nombre: '',
    from_date: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
}

const Filter = ({ setData, setFilter, queryMD, setQueryMD, exportData  }) => {
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = async (values) => {
      let params = {
      ...values,
      from_date: values?.from_date? moment(values.from_date).format("YYYY-MM-DD") : undefined,
      to_date: values?.to_date? moment(values.to_date).format("YYYY-MM-DD") : undefined,
      nombre: values?.nombre || undefined,
    }

    try {
      setSubmitted(true)
      if(!params.nombre || params.nombre.trim()=='')
          return
      console.log(values)
      // const response = await handleGetDocuments(params)
      setQueryMD({ ...queryMD, ...params, pageNumber: 1 })
      // setData(response.data.result)
      setSubmitted(false)
    } catch (error) {
      toast.error(error.message)
      setSubmitted(false)
    }

  }

  useEffect(()=>{
console.log(submitted)

  }, [submitted])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          <div className='row'>
            <div className='col-6'>
              <Input id='nombre' name='nombre' autoComplete="off" 
                  className={`form-control ${submitted && (!values.nombre || values.nombre.trim()=='') ? 'border-danger':''}`}/>
              <p className='text-muted'>Cliente</p>
            </div>
            <div className='col-3'>
              <Input id='from_date' name='from_date' type='date' />
              <p className='text-muted'>Desde</p>
            </div>

            <div className='col-3'>
              <Input id='to_date' name='to_date' type='date' />
              <p className='text-muted'>Hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              {/* <Button variant="secondary" type='button' onClick={() => exportData()} className="me-3">
                <i className="bi bi-file-earmark-excel mb-1"/>
                Exportar
              </Button> */}

              <Button  variant="primary" type='submit' onClick={()=>{}}>
                <i className="bi bi-search mb-1"/>
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter