import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useSalesOrders, useOutOrders, useSellers } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { Filter, AuthModal } from './partials'
import { COLUMNS } from './columns'

import routes from 'app/routing/routes'
import { SalesOrderStatusI, OrderTypeI } from 'interfaces'

export const labelColor = (sales_order_status_id) => {
  switch(sales_order_status_id){
    case SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA:
    case SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO:
    case SalesOrderStatusI.PENDIENTE_PREPARACION:
    case SalesOrderStatusI.PENDIENTE_FACTURACION:
    case SalesOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo";
    case SalesOrderStatusI.ENTREGADO:
      return "estado-verde";
    case SalesOrderStatusI.BORRADOR:
      return "estado-gris";
    case SalesOrderStatusI.FALTA_STOCK:
    case SalesOrderStatusI.EN_REVISION:
    case SalesOrderStatusI.NO_AUTORIZADO:
    case SalesOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja";
    case SalesOrderStatusI.EN_DISTRIBUCION:
    case SalesOrderStatusI.EN_PREPARACION:
    case SalesOrderStatusI.EN_DESPACHO:
      return "estado-azul";
    case SalesOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste";
    case SalesOrderStatusI.CANCELADO:
      return "estado-rojo";
    default:
      return ""
  }
}

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const SalesOrders = () => {
  const { currentUser } = useAuth()

  const intl = useIntl()
  const navigate = useNavigate();

  const [modal, setModal] = useState(false)

  const {
    loading,
    handleGetSalesOrders,
    handleUpdateSalesOrderStatus,
    handleUpdateOutRequestStatus,
    handleCloneSalesOrder,
    handleCreateOutOrder,
    handlePrintSalesOrder,
    handleCheckSalesOrderPVP,
    handleCancelSalesOrderSpecial,
    handleCancelacionParcial,
  } = useSalesOrders()
  const [data, setData] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getSalesOrders = useCallback(async () => {
    try {
      let params = {
        user_id_filter: [currentUser.user_id],
        sales_order_status_id_filter: [SalesOrderStatusI.BORRADOR],
        getDetail: true,
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      if (currentUser && currentUser?.sales_orders) {
        const { sales_orders } = currentUser

        params = {
          ...params,
          id_text: sales_orders?.id_text || '',
          purchase_order: sales_orders?.purchase_order || '',
          out_order_id: sales_orders?.out_order_id || '',
          from_date: sales_orders?.from_date || '',
          to_date: sales_orders?.to_date || '',
          nombre: sales_orders?.nombre || '',
          documento: sales_orders?.documento || '',
          from_auth_date: sales_orders?.from_auth_date || '',
          to_auth_date: sales_orders?.to_auth_date || '',
          paciente: sales_orders?.paciente || '',
          paciente_documento: sales_orders?.paciente_documento || '',
          seller_id_filter: sales_orders?.seller_id_filter || [],
          article_text: sales_orders?.article_text || '',
          user_id_filter: sales_orders?.user_id_filter ? sales_orders.user_id_filter : [],
          grouped_status_id: sales_orders?.grouped_status_id || [],
          sales_order_status_id_filter: sales_orders?.sales_order_status_id_filter ? sales_orders.sales_order_status_id_filter : [],
        }
      }

      const response = await handleGetSalesOrders(params, '')
      const data = response.data.result
      const dataColored = data.map(d => ({
        ...d,
        label_color: labelColor(d.sales_order_status_id)
      }))

      setData(dataColored)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetSalesOrders, query, currentUser])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow) {
      actions.push({
        label: 'Ir a detalle',
        icon: 'bi bi-eye text-primary',
        action: actionEdit,
      })

      if (
        (currentRow.sales_order_status_id === SalesOrderStatusI.BORRADOR) &&
        currentRow.order_type_id === OrderTypeI.PEDIDO_VENTA &&
        parseInt(currentRow.lines) > 0
      ) {
        actions.push({
          label: "Enviar a autorizar",
          icon: "bi bi-box-arrow-in-down-right text-success",
          // action: actionRentAutorize
          action: () => {
            setModal(true)
          }
        })
      }

      if (
        currentRow.sales_order_status_id === SalesOrderStatusI.BORRADOR ||
        currentRow.sales_order_status_id === SalesOrderStatusI.NO_AUTORIZADO ||
        currentRow.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA ||
        currentRow.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO
      ) {
        actions.push({
          label: "Cancelar pedido",
          icon: "bi bi-trash text-danger",
          action: cancelOrder
        })
      }

      if (currentRow.order_type_id === OrderTypeI.PEDIDO_EGRESO && (currentRow.sales_order_status_id !== SalesOrderStatusI.AUTORIZADO && currentRow.sales_order_status_id !== SalesOrderStatusI.CANCELADO)) {
        actions.push({
          label: "Autorizar",
          icon: "bi bi-box-arrow-up-right text-success",
          action: actionAutorize
        })
      }

      if (currentRow.sales_order_status_id === SalesOrderStatusI.AUTORIZADO) {
        actions.push({
          label: "Crear Orden de Egreso",
          icon: "bi bi-box-arrow-up-right text-success",
          action: createOutOrder
        })

        actions.push({
          label: "Cancelar pedido",
          icon: "bi bi-x-circle text-danger",
          action: cancelOrder
        })
      }

      if (
        currentRow.sales_order_status_id === SalesOrderStatusI.FALTA_STOCK ||
        currentRow.sales_order_status_id === SalesOrderStatusI.RESERVA_PARCIAL ||
        currentRow.sales_order_status_id === SalesOrderStatusI.EN_REVISION
      ) {
        if (currentRow.out_orders_ids.split(',').length == 1) {
          actions.push({
            label: 'Cancelar',
            icon: 'bi bi-x-circle text-danger',
            action: cancelSalesOrderSpecial
          })
        }

        if (currentRow.out_orders_ids.split(',').length > 1) {
          actions.push({
            label: 'Cancelar parcialmente',
            icon: 'bi bi-x-circle text-danger',
            action: cancelacionParcial
          })
        }
      }

      actions.push({
        label: "Clonar",
        icon: "bi bi-clipboard-check",
        action: cloneSalesOrder
      })

      actions.push({
        label: 'Descargar',
        icon: 'bi bi-download text-success',
        action: printSalesOrder
      })
    }

    return actions
  }

  const updateSalesOrderStatus = async (newStatus) => {
    try {
      if (currentRow?.order_type_id === OrderTypeI.PEDIDO_VENTA) {
        const response = await handleUpdateSalesOrderStatus(currentRow?.id, newStatus)
        toast.success("Pedido actualizado correctamente", {theme: "colored"})
      }

      if (currentRow?.order_type_id === OrderTypeI.PEDIDO_EGRESO) {
        const response = await handleUpdateOutRequestStatus(currentRow?.id, newStatus)
        toast.success("Pedido actualizado correctamente", {theme: "colored"})
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getSalesOrders()
    }
  }

  const cloneSalesOrder = async () => {
    try {
      const response = await handleCloneSalesOrder(currentRow?.id)
      toast.success("Pedido clonado correctamente", { theme: "colored" })
    } catch (error) {
      toast.error(error.message)
    } finally {
      getSalesOrders()
    }
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(currentRow?.order_type_id === OrderTypeI.PEDIDO_VENTA
        ? `${routes.SALES_ORDERS}/${currentRow.id}`
        : currentRow?.order_type_id === OrderTypeI.PEDIDO_EGRESO
          ? `${routes.OUT_REQUEST}/${currentRow.id}`
          : routes.DASHBOARD
      )
    }
  }

  const actionRentAutorize = async () => {
      if (currentRow.address_id_ship == "" || !currentRow.address_id_ship || currentRow.address_id_traza == "" || !currentRow.address_id_traza) {
        toast.error("El pedido no tiene dirección cargada")
        return
      }

      try {
        const response = await handleCheckSalesOrderPVP(currentRow?.id)

        let text = "¿Enviar a autorizar la rentabilidad del pedido?"
        if (response.data.result.length > 0) {
          let qty = response.data.result.length
          text = `El pedido #${currentRow?.id} tiene (${qty}) detalle${qty > 1 ? `s` : ''} con PVP desactualizado, ¿quiere enviarlo a autorizar?`
        }

        const resp = await Swal.fire({
          title: "Advertencia",
          text: text,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, enviar a autorizar",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          updateSalesOrderStatus(SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA)
        }

        getSalesOrders()
      } catch (error) {
        toast.error(error.message)
      }
  }

  const actionAutorize = () => {
    updateSalesOrderStatus(SalesOrderStatusI.AUTORIZADO)
  }

  const cancelOrder = async() => {
    try {
      const inputValue = ''

      const resp = await Swal.fire({
        title: "Advertencia",
        text: `¿Confirma la cancelación del pedido #${currentRow?.id}?`,
        icon: "warning",
        input:"textarea",
        inputValue,
        inputLabel: "Motivo",
        inputAttributes: {
          autocomplete:"off"
        },
        inputValidator: (value) => {
          if (!value || value.trim() == '') {
            return "Por favor, completar motivo de rechazo"
          }
        },
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si, cancelar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        await handleUpdateSalesOrderStatus(currentRow?.id, SalesOrderStatusI.CANCELADO, { observaciones: resp.value })
        toast.success(`Pedido #${currentRow?.id} cancelado`, { theme: "colored" })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getSalesOrders()
    }
  }

  const createOutOrder = async () => {
    try {
      if (currentRow) {
        const response = await handleCreateOutOrder(currentRow.id)
        toast.success(`Orden #${response.data.id} creada correctamente`, { theme:"colored" })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getSalesOrders()
    }
  }

  const printSalesOrder = async () => {
    if (currentRow) {
      try {
        const response = await handlePrintSalesOrder(currentRow.id)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('¡Pedido descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `pedido-${currentRow.id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          console.error('Error al descargar el PDF')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const cancelSalesOrderSpecial = async () => {
    if (currentRow) {
      try {
        // let params = {
        //   sales_order_id: currentRow.id,
        // }
        // const outOrdersResponse = await handleGetOutOrders(params)
        // const data = outOrdersResponse.data.result

        // if (data.length > 1) {
        //   toast.error(`El Pedido #${currentRow.id} NO se puede cancelar porque cuenta con más de 1 (una) Órden de egreso`);
        //   return
        // }

        const resp = await Swal.fire({
          title: "Advertencia",
          text: `¿Confirma la cancelación del pedido #${currentRow?.id}?`,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cerrar",
          confirmButtonText: "Si, cancelar",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true,
        })

        if (resp.isConfirmed) {
          const response = await handleCancelSalesOrderSpecial(currentRow.id)

          toast.success(response.data.message)
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        getSalesOrders()
      }
    }
  }

  const cancelacionParcial = async () => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a cancelar parcialmente el pedido #${currentRow.id}, ¿confirma?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cerrar",
        confirmButtonText: "Sí, cancelar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        const response = await handleCancelacionParcial(currentRow.id)

        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getSalesOrders()
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
    }
  ], [COLUMNS, getSalesOrders])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getSalesOrders()
  }, [getSalesOrders])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={intl.formatMessage({ id: 'TITLE.SALES_ORDERS' })}
        dropdownDisabled={loading}
        dropdownActions={[
          {
            label: "Pedido de Venta",
            href: routes.SALES_ORDERS_NEW,
            icon: 'currency-dollar',
          },
        ]}
        filter={<Filter handleData={setData} query={query} handleQuery={setQuery} handleTotalCount={setTotalCount} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <AuthModal show={modal} onClose={() => setModal(false)} salesOrder={currentRow} handleGetSalesOrders={getSalesOrders} />

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default SalesOrders