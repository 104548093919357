import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PurchaseIncomeOrderColumnI } from 'interfaces'

import moment from 'moment';
import routes from 'app/routing/routes';
import { Tooltip } from 'components';

const COLUMNS =  [
  {
    Header: "Lote",
    id: 'lot_number',
    accessor: (row) => (
      <div>
        <strong className='m-0 p-0'>{row?.lot_number}</strong>
        <p className='m-0 p-0 text-muted'>{row?.expiration_date ? `Venc.: ${moment(row?.expiration_date).format('DD/MM/YYYY')}` : ''}</p>
      </div>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Unidades</p>,
    id: 'qty',
    accessor: (row) => (
      <p className='p-0 m-0 text-center'>{row.qty}</p>
    ),
  },
  {
    Header: 'ID',
    name: 'id',
    accessor: (row) => (
      <Tooltip text='Ver artículo'>
        <Link to={`${routes.ARTICLES}/${row.id}`} state={{ product_id: row.product_id }}>
          {row.id}
        </Link>
      </Tooltip>
    ),
    hasOrder: true,
  },
  {
    Header: "Artículo",
    id: 'name',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.name}</p>
    ),
    hasOrder: true,
  },
  {
    Header: "",
    id: 'refrigerated',
    accessor: (row) => (
      <>
        {Boolean(row.refrigerated) &&
          <span className='ps-4 pe-4'>
            <i className='bi bi-snow text-primary fs-3' />
          </span>
        }
      </>
    )
  },
  {
    Header: "",
    id: 'traced',
    accessor: (row) => (
      <>
        {Boolean(row.traced) &&
          <span className='ps-4 pe-4'>
            <i className='bi bi-snow text-primary fs-3' />
          </span>
        }
      </>
    )
  },
]

export {
  COLUMNS
}