import { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'

import useTransactionalDocuments from 'hooks/useTransactionalDocuments'
import { Input, SelectField, SwitchField } from 'components'

import { TransactionalDocumentTypeI } from 'interfaces'


const initialValues = {
    nombre: '',
    date_from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
}

const Filter = ({ setData, setFilter, queryMD, setQueryMD, exportData  }) => {

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
         let params = {
          ...values,
          date_from: values?.date_from? moment(values.date_from).format("YYYYMMDD") : undefined,
          date_to: values?.date_to? moment(values.date_to).format("YYYYMMDD") : undefined,
          // transactional_document_type_id: values?.transactional_document_type_id || undefined,
          // sales_order_id: values?.sales_order_id || undefined,
          // punto_venta: values?.punto_venta || undefined,
          nombre: values?.nombre || undefined,
          // status: values?.status || undefined,
          // with_nc:values?.with_nc? 1:0
        }

        try {
          // const response = await handleGetDocuments(params)
          setQueryMD({ ...queryMD, ...params, pageNumber: 1 })
          setFilter(true)
          // setData(response.data.result)
        } catch (error) {
          toast.error(error.message)
        }
      }}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          <div className='row'>
            <div className='col-6'>
              <Input id='nombre' name='nombre' autoComplete="off" />
              <p className='text-muted'>Cliente</p>
            </div>
            <div className='col-3'>
              <Input id='date_from' name='date_from' type='date' />
              <p className='text-muted'>Desde</p>
            </div>

            <div className='col-3'>
              <Input id='date_to' name='date_to' type='date' />
              <p className='text-muted'>Hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              {/* <Button variant="secondary" type='button' onClick={() => exportData()} className="me-3">
                <i className="bi bi-file-earmark-excel mb-1"/>
                Exportar
              </Button> */}

              <Button  variant="primary" type='submit'>
                <i className="bi bi-search mb-1"/>
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter