import moment from 'moment';

/*

*/
const useGetColumns = () => {
  


  const COLUMNS = [
    {
      Header: "ID",
      id: 'id',
      accessor: "id",
      hasOrder:true
    },
    {
      Header:  () => (<div style={{ textAlign: "center" }}>Fecha</div>),
      id: 'fecha',
      accessor: (row) => (
        <div className='text-center'>
          {row?.fecha ? moment(row.fecha).format("DD/MM/YYYY") : '-'}
        </div>
      ),
      hasOrder:true
    },
    {
      Header: 'Tipo',
      id: 'tipo_comprobante',
      accessor: (row) => (
        <div style={{width:"200px"}}>
          {row.tipo_comprobante}
        </div>
      )
    },
    {
      Header:  () => (<div style={{ textAlign: "center" }}>Número</div>),
      id: 'nro_completo',
      accessor: (row) => (
        <div className='text-center'>
          {row.nro_completo}
        </div>
      ),
      hasOrder:true
    },
    {
      Header: "Cliente",
      accessor: 'razon_social',
      hasOrder:true
    },
    {
      Header:  () => (<div style={{ textAlign: "center" }}>Pedido</div>),
      id: 'sales_order_ids',
      accessor: (row) => (
        <div className='text-center'>
          {row.sales_order_ids}
        </div>
      ),
      hasOrder:true
    },
    {
      Header:  () => (<div style={{ textAlign: "center" }}>CAE</div>),
      id: 'cae',
      accessor: (row) => (
        <div className='text-center'>
          {row?.cae || '-'}
        </div>
      ),
      hasOrder:true
    },
    {
      Header:  () => (<div style={{ textAlign: "center" }}>Total</div>),
      id: 'total',
      accessor: (row) => (
        <div className='text-end'>
          $ {Number(row.total).toFixed(2)}
        </div>
      ),
      hasOrder:true
    },


  ]

  return {
    COLUMNS
  }
}

export default useGetColumns