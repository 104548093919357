import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useStock, useDepots, useUsers } from 'hooks'

import { Input, SelectField } from 'components'
import { DepotMovementStatusI } from 'interfaces'

const labelColor = (statusId) => {
  switch (statusId) {
    case DepotMovementStatusI.EN_PREPARACION:
      return "estado-amarillo"
    case DepotMovementStatusI.CONFIRMADO:
      return "estado-verde"
    case DepotMovementStatusI.CANCELADO:
      return 'estado-rojo'
    default:
      return "estado-gris"
  }
}

const initialValues = {
  id: '',
  storage_space_id_origin_filter: [],
  storage_space_id_dest_filter: [],
  depot_movement_status_id_filter: [DepotMovementStatusI.EN_PREPARACION],
  article_text: '',
  remito: '',
  // user_id_filter: [],
  date_from: '',
  date_to: '',
  // cancel_user_id_filter: [],
  // cancel_date_from: '',
  // cancel_date_to: '',
  // confirm_user_id_filter: [],
  confirm_date_from: '',
  confirm_date_to: '',
}

const Filter = ({ setData, setTotalCount, queryMD, setQueryMD }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetDepotMovements, handleGetDepotMovementStatus } = useStock()
  const [status, setStatus] = useState([])

  const { handleGetStorageBusinessUnits } = useDepots()
  const [storages, setStorages] = useState([])

  // const { handleGetUsers } = useUsers()
  // const [users, setUsers] = useState([])

  const filterData = (values) => {
    let params = {
      ...queryMD,
      ...values,
      id: values?.id || undefined,
      storage_space_id_origin_filter: values?.storage_space_id_origin_filter || undefined,
      storage_space_id_dest_filter: values?.storage_space_id_dest_filter || undefined,
      depot_movement_status_id_filter: values?.depot_movement_status_id_filter && values.depot_movement_status_id_filter.length > 0
        ? values.depot_movement_status_id_filter
        : [DepotMovementStatusI.EN_PREPARACION, DepotMovementStatusI.CONFIRMADO, DepotMovementStatusI.CANCELADO],
      article_text: values?.article_text || undefined,
      remito: values?.remito || undefined,
      // user_id_filter: values?.user_id_filter || undefined,
      date_from: values?.date_from || undefined,
      date_to: values?.date_to || undefined,
      // cancel_user_id_filter: values?.cancel_user_id_filter || undefined,
      // cancel_date_from: values?.cancel_date_from || undefined,
      // cancel_date_to: values?.cancel_date_to || undefined,
      // confirm_user_id_filter: values?.confirm_user_id_filter || undefined,
      confirm_date_from: values?.confirm_date_from || undefined,
      confirm_date_to: values?.confirm_date_to || undefined,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = {
        ...filterData(values),
        pageNumber: 1,
        pageSize: 10,
      }
      const response = await handleGetDepotMovements(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.depot_movement_status_id)
      }))

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        depot_movements: {
          ...params,
          depot_movement_status_id_filter: params?.depot_movement_status_id_filter && params.depot_movement_status_id_filter.length > 0
            ? params.depot_movement_status_id_filter
            : []
        }
      }))

      setQueryMD(params)
      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  const getStorages = useCallback(async () => {
    try {
      const response = await handleGetStorageBusinessUnits()
      const data = response.map(d => ({
        ...d,
        value: d.id,
        label: d.name,
      }))

      setStorages(data)
    } catch (error) {
      setStorages([])
      toast.error(error.message)
    }
  }, [handleGetStorageBusinessUnits])

  const getStatus = useCallback(async () => {
    try {
      const response = await handleGetDepotMovementStatus()
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.label
      }))

      setStatus(data)
    } catch (error) {
      setStatus([])
      toast.error(error.message)
    }
  }, [handleGetDepotMovementStatus])

  /*
  const getUsers = useCallback(async () => {
    try {
      let params = {
        is_active: 1
      }
      const response = await handleGetUsers(params)
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.name
      }))

      setUsers(data)
    } catch (error) {
      setUsers([])
      toast.error(error.message)
    }
  }, [handleGetUsers])
  */

  useEffect(() => {
    getStorages()
    getStatus()
    // getUsers()
  }, [getStorages, getStatus, /*getUsers*/])

  useEffect(() => {
    if (currentUser?.user_id) {
      const { depot_movements: dp } = currentUser

      setInitialFormikValues({
        ...initialFormikValues,
        id: dp?.id || '',
        storage_space_id_origin_filter: dp?.storage_space_id_origin_filter || [],
        storage_space_id_dest_filter: dp?.storage_space_id_dest_filter || [],
        date_from: dp?.date_from || '',
        date_to: dp?.date_to || '',
        article_text: dp?.article_text || '',
        depot_movement_status_id_filter: dp?.depot_movement_status_id_filter && dp.depot_movement_status_id_filter.length > 0
          ? dp.depot_movement_status_id_filter
          : [DepotMovementStatusI.EN_PREPARACION]
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id' name='id' disabled={loading} />
              <p className='text-muted'>Nro.</p>
            </div>

            <div className='col-5'>
              <SelectField
                id='storage_space_id_origin_filter'
                name='storage_space_id_origin_filter'
                options={storages}
                placeholder='Seleccionar almacen origen'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Almacen Origen</p>
            </div>

            <div className='col-5'>
              <SelectField
                id='storage_space_id_dest_filter'
                name='storage_space_id_dest_filter'
                options={storages}
                placeholder='Seleccionar almacen destino'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Almacen Destino</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-8'>
              <Input id='article_text' name='article_text' disabled={loading} />
              <p className='text-muted'>ID / Artículo / Droga / Laboratorio</p>
            </div>

            <div className='col-2'>
              <Input id='date_from' name='date_from' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. creación</p>
            </div>

            <div className='col-2'>
              <Input id='date_to' name='date_to' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. creación</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-3'>
              <Input id='remito' name='remito' disabled={loading} />
              <p className='text-muted'>Remito</p>
            </div>

            <div className='col-5'>
              <SelectField
                id='depot_movement_status_id_filter'
                name='depot_movement_status_id_filter'
                options={status}
                placeholder='Seleccionar estado'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Estado</p>
            </div>

            <div className='col-2'>
              <Input id='confirm_date_from' name='confirm_date_from' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. confirmación</p>
            </div>

            <div className='col-2'>
              <Input id='confirm_date_to' name='confirm_date_to' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. confirmación</p>
            </div>
          </div>

          <div className="row">
            <div className='col text-end'>
              <Button
                type='button'
                variant='secondary'
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className="bi bi-search mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant='primary' disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter